<template>
	<div class="query-content">
		<div class="query-header">
			<div class="query-search">
				<div class="number-search">
					<el-input :placeholder="searchPlaceholder" v-model="searchInput" @input="handleInputVin" class="input-with-select">
						<el-select v-model="searchSelect" slot="prepend" placeholder="请选择" @change="searchSelectChange">
							<el-option v-for="item in searchOptions" :key="item.value" :label="item.label"
								:value="item.value">
							</el-option>
						</el-select>
						<el-button @click="handleSearch" slot="append" icon="el-icon-search" class="main-btn">
						</el-button>
					</el-input>
					<el-button class="main-btn verify-btn" @click="verifyBtn">
						<i class="iconfont">&#xe623;</i>防伪验证
					</el-button>
				</div>
				<div class="keyword-search" v-if="pinfo.productCode">
					<el-input clearable placeholder="请输入备品零件号或备品名称搜索" v-model="query.keyword" class="input-with-select">
						<el-button @click="handleKeywordSearch" slot="append" icon="el-icon-search" class="main-btn">
						</el-button>
					</el-input>
				</div>
			</div>

			<div class="info">
				<div class="info-item">
					<div class="title">品牌</div>
					<div class="content">
						<el-tooltip content="解放" placement="top-start">
							<div class="line2">解放</div>
						</el-tooltip>
					</div>
				</div>
				<div class="info-item">
					<div class="title">品牌说明</div>
					<div class="content">
						<el-tooltip :content="pinfo.brandText" placement="top-start">
							<div class="line2">{{pinfo.brandText}}</div>
						</el-tooltip>
					</div>
				</div>
				<div class="info-item">
					<div class="title">品系</div>
					<div class="content">
						<el-tooltip :content="pinfo.pdtSeriesText" placement="top-start">
							<div class="line2">{{pinfo.pdtSeriesText}}</div>
						</el-tooltip>
					</div>
				</div>
				<div class="info-item">
					<div class="title">品名</div>
					<div class="content">
						<el-tooltip :content="pinfo.productText" placement="top-start">
							<div class="line2">{{pinfo.productText}}</div>
						</el-tooltip>
					</div>
				</div>
                <div class="info-item">
                    <div class="title">车辆下线日期</div>
                    <div class="content">
                        <el-tooltip :content="this.common.formatDate(vinInfo.offlineDate, 'yyyy-MM-dd')" placement="top-start">
                            <div class="line2">{{this.common.formatDate(vinInfo.offlineDate, 'yyyy-MM-dd')}}</div>
                        </el-tooltip>
                    </div>
                </div>
				<div class="info-item">
					<div class="title">排放</div>
					<div class="content">
						<el-tooltip :content="pinfo.emissionTypeText" placement="top-start">
							<div class="line2">{{pinfo.emissionTypeText}}</div>
						</el-tooltip>
					</div>
				</div>
				<div class="info-item">
					<div class="title">颜色</div>
					<div class="content">
						<el-tooltip :content="pinfo.colorText" placement="top-start">
							<div class="line2">{{pinfo.colorText}}</div>
						</el-tooltip>
					</div>
				</div>
				<div class="info-item">
					<div class="title">发动机</div>
					<div class="content">
						<el-tooltip :content="pinfo.engineTypeText" placement="top-start">
							<div class="line2">{{pinfo.engineTypeText}}</div>
						</el-tooltip>
					</div>
				</div>
				<div class="info-item">
					<div class="title">驾驶室</div>
					<div class="content">
						<el-tooltip :content="pinfo.cabTypeConfigText" placement="top-start">
							<div class="line2">{{pinfo.cabTypeConfigText}}</div>
						</el-tooltip>
					</div>
				</div>
				<div class="info-item">
					<div class="title">变速箱</div>
					<div class="content">
						<el-tooltip :content="pinfo.gearboxAndModelText" placement="top-start">
							<div class="line2">{{pinfo.gearboxAndModelText}}</div>
						</el-tooltip>
					</div>
				</div>
				<div class="info-item">
					<div class="title">后桥</div>
					<div class="content">
						<el-tooltip :content="pinfo.realAxleAssemblyText" placement="top-start">
							<div class="line2">{{pinfo.realAxleAssemblyText}}</div>
						</el-tooltip>
					</div>
				</div>
				<div class="info-item">
					<div class="title">后桥速比</div>
					<div class="content">
						<el-tooltip :content="pinfo.realAxleSpeedText" placement="top-start">
							<div class="line2">{{pinfo.realAxleSpeedText}}</div>
						</el-tooltip>
					</div>
				</div>
				<div class="info-item">
					<div class="title">轮胎</div>
					<div class="content">
						<el-tooltip :content="pinfo.tyreTypeText" placement="top-start">
							<div class="line2">{{pinfo.tyreTypeText}}</div>
						</el-tooltip>
					</div>
				</div>
				<div class="info-item">
					<div class="title">轴距</div>
					<div class="content">
						<el-tooltip :content="pinfo.freightBoxText" placement="top-start">
							<div class="line2">{{pinfo.freightBoxText}}</div>
						</el-tooltip>
					</div>
				</div>
				<div class="info-item w50">
					<div class="title">前桥</div>
					<div class="content">
						<el-tooltip :content="pinfo.farwardText" placement="top-start">
							<div class="line2">{{pinfo.farwardText}}</div>
						</el-tooltip>
					</div>
				</div>
				<div class="info-item w100">
					<div class="title">其他</div>
					<div class="content">
						<el-tooltip :content="pinfo.others" placement="top-start">
							<div class="line2">{{pinfo.others}}</div>
						</el-tooltip>
					</div>
				</div>
			</div>
		</div>

		<el-table :data="tableData" class="main-table" header-row-class-name="table-header" height="5.4rem"
			v-loading="loading"
                  :empty-text="loading ? '正在检索数据库' : '暂无数据'"
                  cell-class-name="table-hover" :row-class-name="tableRowClassName"
			@selection-change="handleSelectionChange" @sort-change="handleSortChange"
        >
			<el-table-column type="selection" width="70" align="center"></el-table-column>
			<el-table-column label="序号" type="index" width="80" align="center">
				<template scope="scope">
					<span>{{(query.page - 1) * query.pagesize + scope.$index + 1}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="buyCode" label="备品零件号" width="260"></el-table-column>
			<el-table-column prop="name" label="备品名称" width="300"></el-table-column>
			<el-table-column prop="priceEndUser" label="指导价" sortable="custom" align="center">
                <template scope="{row}">
                    <span>{{ calcPrice(row.priceEndUser, row.priceXichai) }}</span>
                </template>
            </el-table-column>
			<el-table-column prop="price2" label="服务站价" align="center">
				<template scope="{row}">
					{{ row.priceServiceTax ? row.priceServiceTax : '-' }}
				</template>
			</el-table-column>
			<el-table-column prop="price3" label="供货价" align="center"></el-table-column>
			<el-table-column prop="qty" label="库存" sortable="custom" align="center"></el-table-column>
			<el-table-column label="照片" align="center" width="100" class-name="photo">
				<template slot-scope="{row}">
					<el-image class="photo-img" :src="row.imgs[0].img" v-if="row.imgs && row.imgs.length>0"
						:preview-src-list="row.imgs.map(e=>e.img)" @click="bigImg(row.imgs[0].img)"></el-image>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="140" align="center" class-name="table-operate">
				<template slot-scope="{row}">
					<el-button v-if="row.buyCode && row.id" class="operation-btn" type="warning" size="small" title="资料修改" @click="goDetail(row)">
						详细参数</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="bottom">
			共选中<em>{{selectedList.length}}</em>个备品，合计（元）：<em>{{selectedPriceTotal}}</em>
			<el-button class="main-btn verify-btn" @click="handleDownload">
				<i class="iconfont">&#xe742;</i>导出表格
			</el-button>
		</div>
		<el-pagination background layout="prev, pager, next" :total="total" :current-page.sync="query.page"
			:page-size="query.pagesize" @current-change="getList">
		</el-pagination>
		<div class="query-footer">
			<el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="[footerImgUrl]" />
			<div class="img-icon-item" @click="onPreview(1)">
				<div class="img-icon"><i class="iconfont">&#xe823;</i>合格样照</div>
			</div>
			<div class="img-icon-item" @click="onPreview(2)">
				<div class="img-icon"><i class="iconfont">&#xe618;</i>防伪查询证明</div>
			</div>
			<div class="tips">
				防伪提示：请点击防伪验证，按操作进行一汽解放正品认证
			</div>
		</div>
		<!-- 防伪验证弹窗 -->
		<el-dialog title="防伪验证" :visible.sync="dialogVisible" custom-class="main-dialog" width="1000px">
			<div class="verification">
				<div class="qr-code">
					<el-image :src="require('@/assets/img/verification01.jpg')" fit="contain"></el-image>
					<p>公众号：faw_jfh</p>
					<p>扫描上方二维码或搜索(解放鹰雄汇)搜索公众号</p>
				</div>
				<el-image class="verify-img" :src="require('@/assets/img/verification02.jpg')" fit="contain"></el-image>
				<el-image class="verify-img" :src="require('@/assets/img/verification03.jpg')" fit="contain"></el-image>
				<el-image class="verify-img" :src="require('@/assets/img/verification04.jpg')" fit="contain"></el-image>
				<el-image :src="require('@/assets/img/verification05.jpg')" fit="contain"></el-image>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">知道了</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
    import upList from "@/api/upList";

	export default {
		components: {
			ElImageViewer
		},
		props: ['categoryId'],
		data() {
			return {
				loading: false,
				dialogVisible: false,
				searchPlaceholder: "请输入底盘号后八位",
				searchOptions: [{
						value: '1',
						label: '底盘号'
					},
					//     {
					// 	value: '2',
					// 	label: '发动机号'
					// }, {
					// 	value: '3',
					// 	label: '车牌号'
					// }
				],
				vinInfo: {},
				pinfo: {},
				showViewer: false, // 显示查看器
				footerImgUrl: '',
				selectedList: [],
				selectedPriceTotal: 0,
				srcList: [
					'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
					'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg'
				],
				searchInput: '',
				keywordSearchInput: '',
				keywordSearchInputShow: false,
				searchSelect: "1",
				tableData: [],
				total: '',
				query: {
					keyword: '',
					categoryId: '',
					page: 1,
					pagesize: 12,
					sortProp: 'price_end_user',
					sortOrder: 'desc'
				}
			}
		},
		watch: {
			categoryId() {
				this.query.categoryId = this.categoryId
				this.query.page = 1
				this.getList()
			}
		},
		methods: {
            handleInputVin(e){
                this.searchInput = e.toUpperCase()
            },
            calcPrice(price, xichai){
                return this.common.calcPrice(price, xichai)
            },
            calcPriceService(price, xichai){
                return this.common.calcPriceService(price, xichai)
            },
			handleSortChange(e) {
				let prop = e.prop == 'priceEndUser' ? 'price_end_user' : e.prop
				this.query.sortOrder = e.order
				this.query.sortProp = prop
				this.query.page = 1
				this.getList()
			},
			verifyBtn() {
				this.dialogVisible = true
			},
			goDetail(row) {
				const detail = this.$router.resolve({
					name: 'sparesView',
					query: {
						id: row.id
					}
				})
				window.open(detail.href, '_blank')
			},
			searchSelectChange(val) {
				if (val == 1) {
					this.searchPlaceholder = "请输入底盘号"
				} else if (val == 2) {
					this.searchPlaceholder = "请输入发动机号"
				} else if (val == 3) {
					this.searchPlaceholder = "请输入车牌号"
				}
			},
			handleKeywordSearch() {
				this.query.page = 1
				this.getList()
			},
			handleSearch() {
				this.searchInput = this.searchInput.trim()
				if (!this.searchInput) {
					this.$message.error('请输入搜索内容')
					return
				}
                if(this.searchSelect == 1){
                    if(this.searchInput.length != 8){
                        this.$message.error('请输入正确的底盘号后八位')
                        return
                    }
                }
				this.query.page = 1
				this.keywordSearchInputShow = true
				this.pinfo = {}
				this.tableData = []
				this.loading = true
				this.$http.fetchGet('api/admin/vin', {
					type: this.searchSelect,
					condition: this.searchInput
				}).then(res => {
					if (res.data.code == 0) {
                        this.vinInfo = res.data.data;
                        if (!this.vinInfo) {
                            this.loading = false
                            if (this.searchSelect == 1) {
                                this.$message.error('请输入正确的底盘号后八位');
                                return
                            } else if (this.searchSelect == 2) {
                                this.$message.error('请输入正确的发动机号');
                                return
                            }
                        }
						this.getPinfo()
					} else if (res.data.code == 50008){
                        this.$message.error('登录信息已失效，请重新登录')
                        return
                    } else {
                        this.loading = false
                        if(this.searchSelect == 1) {
                            this.$message.error('请输入正确的底盘号后八位');
                        }else if(this.searchSelect == 2){
                            this.$message.error('请输入正确的发动机号');
                        }
					}
				})
			},
			getPinfo() {
				this.$http.fetchGet('api/admin/pinfo', {
					id: this.vinInfo.pinfoId
				}).then(res => {
					if (res.data.code == 0) {
						this.pinfo = res.data.data;
						if (!this.pinfo) {
                            this.loading = false
							this.$message.error('没有查询到相关信息');
							return
						}
						this.getList()
					} else {
                        this.loading = false
						this.$message.error('没有查询到相关信息');
					}
				})
			},
			getList() {
				let query = Object.assign({}, this.query)
				query.pinfoId = this.pinfo.id
				query.categoryId = this.categoryId
                this.loading = true
                if(query.categoryId >= 10000){
                    upList.getList(upList.data, query).then(res => {
                        this.loading = false
                        this.tableData = res.list
                        this.total = res.total
                    })
                }else {
                    this.$http.fetchGet('api/admin/product/listbypinfoid', query).then(res => {
                        if (res.data.code == 0) {
                            this.tableData = res.data.data.list;
                            this.total = res.data.data.total
                        }
                    }).finally(() => {
                        this.loading = false
                    })
                }
			},
			onPreview(index) {
				if (index == 1) {
					this.footerImgUrl = require('@/assets/img/certificate.png')
				} else if (index == 2) {
					this.footerImgUrl = require("@/assets/img/prove.png")
				}
				this.showViewer = true
			},
			closeViewer() {
				this.showViewer = false
			},
			bigImg(url) {
				console.log(url)
				this.srcList[0] = url;
			},
			handleSelectionChange(val) {
				this.selectedList = val;
				this.selectedPriceTotal = val.reduce((sum, item) => {
					return this.common.accAdd(sum, item.priceEndUser)
				}, 0)

			},
			tableRowClassName({
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
			handleDownload() {
				if (this.selectedList.length == 0) {
					this.$message.error('请选择要下载的数据')
					return
				}
				this.downloadLoading = true
				import('@/vendor/Export2Excel').then(excel => {
					const tHeader = ['备品零件号', '备品名称', '指导价', '服务站价', '库存']
					const filterVal = ['buyCode', 'name', 'priceEndUser', 'priceService', 'qty']
					let list = this.selectedList
					const data = this.formatJson(filterVal, list)
					excel.export_json_to_excel({
						header: tHeader,
						data,
						filename: '备件列表',
						autoWidth: true,
						bookType: 'xlsx'
					})
					this.downloadLoading = false
				})
			},
			formatJson(filterVal, jsonData) {
				return jsonData.map(v => filterVal.map(j => {
					if (j === 'day') {
						return this.dayFormat({
							min: v['min'],
							max: v['max']
						})
                    } else if (j === 'priceEndUser') {
                        return this.calcPrice(v['priceEndUser'], v['priceXichai'])
					} else if (j === 'priceService') {
						return this.calcPriceService(v['priceEndUser'], v['priceXichai'])
					} else {
						return v[j]
					}
				}))
			},
		}
	}
</script>

<style>
</style>
