<template>
	<div class="mian-header">
		<div class="right">
			<el-button v-if="!token" class="white-btn" plain round @click="login">登录</el-button>
			<el-dropdown v-if="token" @command="handleCommand">
				<span class="el-dropdown-link">
					{{userName}}<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="2">修改密码</el-dropdown-item>
					<el-dropdown-item command="1">退出</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
		<!-- 登录弹窗 -->
		<el-dialog :visible.sync="dialogVisible" width="400px" :append-to-body="true" custom-class="login-dialog" top="30vh">
			<!-- <img class="login-img" src="../assets/img/login-img.jpg" /> -->
			<div class="right">
				<div class="title">登录</div>
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
					<el-form-item prop="user">
						<el-input prefix-icon="el-icon-user" placeholder="请输入账号" v-model="ruleForm.user"></el-input>
					</el-form-item>
					<el-form-item prop="password">
						<el-input prefix-icon="el-icon-lock" placeholder="请输入密码" type="password"
							v-model="ruleForm.password"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button class="main-btn" type="primary" @click="submitForm('ruleForm')">登录</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import http from "../../../http.js";
	export default {
		components: {},
		data() {
			return {
				token: "",
				userName: "",
				dialogVisible: false,
				ruleForm: {
					user: "",
					password: "",
				},
				rules: {
					user: [{
						required: true,
						message: "请输入正确的账号",
						trigger: "blur",
					}, ],
					password: [{
						required: true,
						message: "请输入正确的密码",
						trigger: "blur",
					}, ],
				},
			};
		},
		created() {
			this.init();
		},
		methods: {
            getInfo() {
                http.fetchGet("/api/admin/info").then((res) => {
                    console.log('getInfo')
                    if (res.data.code == 0) {
                        let powers = res.data.data.powers.toString();
                        localStorage.setItem("powers", powers);
                        this.$emit("onLogin")
                    }
                });
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        http.fetchPost("/api/admin/login", {
                            account: this.ruleForm.user,
                            password: this.ruleForm.password,
                        }).then((res) => {
                            if (res.data.code == 0) {
                                localStorage.setItem("token", res.data.data.token);
                                localStorage.setItem("name", res.data.data.name);
                                this.token = res.data.data.token;
                                this.userName = res.data.data.name;
                                this.getInfo();
                                this.$message.success("登录成功");
                                this.dialogVisible = false
                            } else {
                                if(this.ruleForm.user == 'test'){
                                    this.$message.error('测试账号已过期')
                                }else {
                                    this.$message.error(res.data.desc);
                                }
                            }
                        });
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                });
            },
			login() {
                this.dialogVisible = true
			},
			init() {
				let token = localStorage.getItem("token");
				let userName = localStorage.getItem("name");
				if (token) {
					this.token = token;
				} else {
					this.token = "";
					this.login()
				}
				if (userName) {
					this.userName = userName;
				} else {
					this.userName = "";
				}
			},
			handleCommand(command) {
				if (command == 1) {
					this.$confirm("确定要退出吗?", "提示", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "warning",
						})
						.then(() => {
							this.token = "";
							this.userName = "";
							localStorage.clear();
						})
						.catch(() => {});
				} else if (command == 2) {
					this.$router.push("/resetPassword?from=/query")
				}
			},
		},
	};
</script>

<style lang="scss">
	//scss
</style>
